<template>
  <div class="max-w-lg mx-auto p-3">
    <a-divider>User Profile</a-divider>

    <!-- Username -->
    <div class="mb-3">
      <a-typography-title :level="5">Name</a-typography-title>
      <div class="flex items-center space-x-2">
        <a-input v-model:value="user.name" placeholder="Enter your name" class="flex-grow" />
      </div>
    </div>

    <!-- Email -->
    <div class="mb-6">
      <a-typography-title :level="5">Email</a-typography-title>
      <div class="flex items-center space-x-2">
        <a-input v-model:value="user.email" placeholder="Enter your email address" class="flex-grow" />
      </div>
    </div>

    <!-- Profile Actions -->
    <div class="flex justify-end space-x-4">
      <a-button type="primary" @click="handleSaveUserInfo">Save</a-button>
    </div>

    <!-- Password -->
    <a-divider>Password Update</a-divider>

    <div ref="refPassword">
      <a-typography-title :level="5">Password</a-typography-title>
      <div class="flex items-center space-x-2">
        <a-input-password v-model:value="user.password" placeholder="Enter a new password" class="flex-grow mb-6" />
      </div>

      <a-typography-title :level="5">Password confirmation</a-typography-title>
      <a-input-password v-model:value="user.password_confirmation" placeholder="Enter a new password confirmation"
        class="flex-grow mb-6" />
      <span v-if="passwordErrors.length > 0" id="error-message" class="text-red-500 text-sm mt-2">{{
        passwordErrors.join(', ')
        }}</span>
    </div>

    <!-- Profile Actions -->
    <div class="flex justify-end space-x-4">
      <a-button type="primary" @click="handleSaveUserPassword">Update Password</a-button>
    </div>

    <!-- <a-divider>Link Profile</a-divider>
    <div>
      <a-button type="primary">Google</a-button>
      <a-button type="primary">Github</a-button>
    </div> -->
  </div>


</template>

<script setup>
import userAPI from '@/api/user';
import { validatePassword } from '@/utils/validation';
import { onMounted, ref } from 'vue';

const user = ref({
  name: '',
  email: '',
  password: '',
  password_confirmation: '',
});

const passwordErrors = ref([]);

const handleSaveUserInfo = async () => {
  userAPI.updateInfo(user.value.email, user.value.name)
};

const handleSaveUserPassword = () => {
  passwordErrors.value = validatePassword(user.value.password, user.value.password_confirmation);

  if(passwordErrors.value.length === 0){
    userAPI.updatePassword(user.value.password);
  }
};

onMounted(async () => {
  const responce = await userAPI.get();

  user.value.email = responce.email
  user.value.name = responce.name
  user.value.id = responce.id
})
</script>
