import api from '@/utils/axios';
import { useToast } from 'vue-toast-notification';

const $toast = useToast();
const url = '/api/v1/push/notification';

const pushNotificationAPI = {
  async sendPushNotification(data) {
    const headers = {
      'Content-Type': 'application/json',
      'x-api-token': data.projectToken,
    };

    return api
      .post(
        url,
        {
          user_id: data.userId,
          title: data.title,
          message: data.message,
          picture: data.picture,
          large_icon_url: data.large_icon_url,
          big_large_icon_url: data.big_large_icon_url,
        },
        { headers }
      )
      .then((response) => {
        $toast.success('Sent');
        return response;
      })
      .catch((error) => {
        $toast.error('Oops... Try again');
        throw error;
      });
  },
};

export default pushNotificationAPI;
