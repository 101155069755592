<template>
  <div class="container">
    <a-divider>Config</a-divider>


    <div class="row" ref="refMaintenance">
      <a-typography-title :level="5">Maintenance</a-typography-title>

      <div>
        <a-radio-group v-model:value="this.config.maintenance.enabled" option-type="button" :options="[
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ]" @change="maintenanceFormSubmit" />
      </div>
    </div>

    <a-divider>Tokens</a-divider>

    <div class="row" ref="refProjectID">
      <a-typography-title :level="5">Project ID</a-typography-title>

      <div>
        <a-input-password :value="this.project?.id" placeholder="id" />
      </div>
    </div>

    <div class="row" ref="refProjectToken">
      <a-typography-title :level="5">Project Token</a-typography-title>

      <div>
        <a-input-password :value="this.project?.token" placeholder="token" />
      </div>
    </div>

    <div class="row" ref="refApiToken">
      <a-typography-title :level="5">API Token</a-typography-title>

      <div class="row">
        <a-input-password :value="this.project?.api_token" placeholder="API token" />
        <a-button @click="generateNewAPIToken">Regenerate</a-button>
      </div>
    </div>

    <a-divider>ENV</a-divider>

    <div class="row" ref="refDisplayName">
      <a-typography-title :level="5">Display Name</a-typography-title>

      <div>
        <a-input-group compact class="row">
          <a-input v-model:value="this.config.system_env.display_name" placeholder="My App" />
          <a-button @click="displayNameSubmit" type="primary">Submit</a-button>
        </a-input-group>
      </div>
    </div>

    <div class="row" ref="refPackageName">
      <a-typography-title :level="5">Package Name</a-typography-title>

      <div>
        <a-input-group compact class="row">
          <a-input v-model:value="this.config.system_env.package_name" placeholder="com.company.my_app" />
          <a-button @click="packageNameSubmit" type="primary">Submit</a-button>
        </a-input-group>
      </div>
    </div>

    <div v-for="(env, index) in userEnvForm" :key="env">
      <div class="row">
        <a-input class="w-44 h-8" v-model:value="env.name" placeholder="HOST" />

        <div>
          <a-input-group compact class="row">
            <a-input v-model:value="env.value" placeholder="http://localhost" />
            <a-button @click="addUserEnv(env)" type="primary">Submit</a-button>
            <a-button @click="deleteUserEnv(env)" danger>Remove</a-button>
          </a-input-group>
        </div>
      </div>
    </div>

    <div class="flex items-center justify-center">
      <a-button @click="addUserEnvRow" type="primary">Add</a-button>
    </div>


    <div v-if="this.buildStatus && this.buildStatus !== 'not_found'" class="row">
      <a-steps :current="this.statusIndexes[this.buildStatus]"
        :status="this.statusIndexes[this.buildStatus] ? '' : 'error'" :items="[
          {
            title: 'Available',
          },
          {
            title: 'Scheduled',
          },
          {
            title: 'In Progress',
          },
          {
            title: 'Completed',
          },
        ]"></a-steps>
    </div>

    <div class="row" v-if="this.buildExist">
      <a-typography-title :level="5">Download Build</a-typography-title>

      <div>
        <a-button type="primary" :size="'default'" @click="handleDownloadBuildClick">
          <template #icon>
            <DownloadOutlined />
          </template>
          Download
        </a-button>
      </div>
    </div>

    <div v-if="this.buildStatus && this.buildStatus !== 'not_found'">
      <a-button type="primary" @click="showModal">Show Logs</a-button>
      <a-modal v-model:open="open" width="1000px" title="Logs" @ok="handleOk" @cancel="handleOk">
        <pre class="modal-logs">{{ buildLogs }}</pre>
      </a-modal>
    </div>
  </div>
  <a-tour v-model:current="current" :open="openTour" :steps="steps" @close="handleTourClose" />
</template>

<script>
import { onMounted, ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { DownloadOutlined } from '@ant-design/icons-vue';
import { useToast } from 'vue-toast-notification';
import androidBuildAPI from '../../api/androidBuild';

const $toast = useToast();

export default {
  name: 'ConfigPage',
  components: {
    DownloadOutlined,
  },
  setup() {
    const openTour = ref(false);
    const current = ref(0);
    const refMaintenance = ref(null);
    const refProjectID = ref(null);
    const refProjectToken = ref(null);
    const refApiToken = ref(null);
    const refDisplayName = ref(null);
    const refPackageName = ref(null);
    const refGenerateBuild = ref(null);

    const steps = [
      {
        title: 'Maintenance Mode',
        description: 'Enable or disable maintenance mode for the project.',
        target: () => refMaintenance.value,
      },
      {
        title: 'Project ID',
        description:
          'Here is the project ID. It is used to identify your project.',
        target: () => refProjectID.value,
      },
      {
        title: 'Project Token',
        description:
          'This is the project token. It is used for authentication.',
        target: () => refProjectToken.value,
      },
      {
        title: 'API Token',
        description:
          'This is the api token. It is used for authentication.',
        target: () => refApiToken.value,
      },
      {
        title: 'Display Name',
        description: 'Set the display name of your project.',
        target: () => refDisplayName.value,
      },
      {
        title: 'Package Name',
        description: 'Set the package name for your project.',
        target: () => refPackageName.value,
      }
    ];

    const handleTourClose = () => {
      localStorage.setItem('tourCompleted-settings-page', 'true');
      openTour.value = false;
    };

    onMounted(() => {
      if (!localStorage.getItem('tourCompleted-settings-page')) {
        openTour.value = true;
      }
    });

    return {
      openTour,
      current,
      steps,
      handleTourClose,
      refMaintenance,
      refProjectID,
      refProjectToken,
      refApiToken,
      refDisplayName,
      refPackageName,
      refGenerateBuild,
    };
  },
  data() {
    return {
      statusIndexes: {
        not_found: -1,
        available: 1,
        scheduled: 2,
        executing: 3,
        completed: 4,
      },
      buildStatusIntervalId: null,
      buildLogIntervalId: null,
      buildStatus: null,
      buildExist: false,
      buildLogs: null,
      userId: this.$store.getters.getUserID,
      project_id: this.$route.params.project_id,
      open: false,
      config: {
        login: {
          enabled: false,
        },
        maintenance: {
          enabled: false,
        },
        system_env: {
          package_name: null,
          display_name: null,
        },
        user_env: {}
      },
      userEnvForm: []
    };
  },
  mounted() {
    this.initConfig();
    this.getAllProjects(this.userId);
    // this.getBuildStatus();
    // this.getBuildExist();
    // this.buildStatusIntervalId = setInterval(this.getBuildStatus, 20000);
  },
  unmounted() {
    clearInterval(this.buildStatusIntervalId);
    clearInterval(this.buildLogIntervalId);
  },
  methods: {
    ...mapActions([
      'getConfigs',
      'requireLogin',
      'enableMaintenance',
      'getAllProjects',
      'updateConfig',
      'updateSystemEnv',
      'updateUserEnv',
      'removeUserEnv',
      'regenerateAPIToken'
    ]),
    ...mapGetters(['getConfigByName', 'configList']),
    initConfig() {
      let promise = this.getConfigs(this.project_id);
      promise.then((config) => {
        config.forEach((element) => {
          this.config[element.name] = element.value;
        });

        if (this.config.user_env) {
          Object.keys(this.config.user_env).forEach((key) => {
            this.userEnvForm.push({ name: key, value: this.config.user_env[key] })
          })
        }
      });
    },
    maintenanceFormSubmit() {
      const body = {
        enabled: this.config.maintenance.enabled,
        project_id: this.project_id,
      };
      this.enableMaintenance(body);
    },
    packageNameSubmit() {
      if (!this.config.system_env.package_name) return;
      const body = {
        value: this.config.system_env.package_name,
        name: 'package_name',
        project_id: this.project_id,
      };
      this.updateSystemEnv(body);
    },
    displayNameSubmit() {
      if (!this.config.system_env.display_name) return;

      const body = {
        value: this.config.system_env.display_name,
        name: 'display_name',
        project_id: this.project_id,
      };
      this.updateSystemEnv(body);
    },
    generateNewAPIToken(){
      this.regenerateAPIToken({ project_id: this.project_id});
    },
    showModal() {
      this.open = true;
      this.buildLogIntervalId = setInterval(this.getBuildLogs, 5000);
    },
    handleOk() {
      this.open = false;
      clearInterval(this.buildLogIntervalId);
    },
    addUserEnvRow() {
      this.userEnvForm.push({
        name: null,
        value: null,
      })
    },
    deleteUserEnv(env) {
      const body = {
        project_id: this.project_id,
        name: env.name,
      };
      this.removeUserEnv(body).then(() => {
        this.userEnvForm = [];
        this.initConfig();
      });
    },
    addUserEnv(env) {
      if (!env.name || !env.value) return;
      const body = {
        value: env.value,
        name: env.name,
        project_id: this.project_id,
      };
      this.updateUserEnv(body);
    },
    async handleGenerateBuildClick() {
      try {
        await androidBuildAPI.generateAndroidBuild(this.project_id);
        this.buildStatus = 'available';
        this.buildStatusIntervalId = setInterval(this.getBuildStatus, 20000);
        $toast.success('Build started');
      } catch (e) {
        $toast.error(e.message);
      }
    },
    async getBuildStatus() {
      try {
        const data = await androidBuildAPI.getBuildStatus(this.project_id);
        this.buildStatus = data?.status;
        if (!this.buildStatus) return;

        if (
          this.buildStatus === 'completed' ||
          this.buildStatus === 'not_found'
        ) {
          if (this.buildStatus === 'completed') {
            this.buildExist = true;
          }

          clearInterval(this.buildStatusIntervalId);
        }
      } catch (e) {
        $toast.error(e.message);
      }
    },
    async getBuildExist() {
      await androidBuildAPI.checkBuildExist(this.project_id);
      this.buildExist = true;
    },
    async getBuildLogs() {
      const logs = await androidBuildAPI.getBuildLogs(this.project_id);
      this.buildLogs = logs;
    },
    async handleDownloadBuildClick() {
      try {
        const response = await androidBuildAPI.downloadBuildFile(
          this.project_id
        );
        // Create a URL from the Blob
        const url = window.URL.createObjectURL(new Blob([response]));

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.project_id}.apk`); // Set the file name

        // Append to the document and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      } catch (e) {
        $toast.error('File not found');
      }
    },
  },
  computed: {
    project: {
      get() {
        return this.$store.getters.projectList.find(
          (x) => x.id === this.project_id
        );
      },
    },
  },
};
</script>
<style scoped>
.row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.modal-logs {
  max-height: 600px;
}

:deep(.input) {
  max-width: 50%;
}

/* #login-form {
  display: flex;
  justify-content: space-between;
} */
</style>
