<template>
  <div class="product-catalog">
    <a-list item-layout="vertical" :data-source="products"
      :grid="{ gutter: [24, 24], xs: 1, sm: 2, md: 3, lg: 4, xl: 4, xxl: 5 }">
      <template #renderItem="{ item }">
        <a-list-item key="item.id">
          <router-link :to="{ name: 'ScreenStoreDetails', params: { id: item.id } }">
            <div class="product-item">
              <div class="product-image">
                <div class="placeholder-image">No image</div>
              </div>
              <div class="product-info">
                <h3 class="product-title">{{ item.title }}</h3>
                <p class="product-description">{{ item.description }}</p>
              </div>
            </div>
          </router-link>
        </a-list-item>
      </template>
    </a-list>
  </div>
</template>

<script>
import { ref, computed, defineComponent, onMounted } from 'vue';
import { Input, Select, List } from 'ant-design-vue';
import { mapActions, useStore } from 'vuex';

export default defineComponent({
  components: {
    AInputSearch: Input.Search,
    ASelect: Select,
    ASelectOption: Select.Option,
    AList: List,
    AListItem: List.Item,
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      store.dispatch('getAllPublishedScreens');
    });

    const products = computed(() => {
      return store.getters.allPublishedScreens;
    });
    return {
      products,
    };
  },
});
</script>

<style scoped>
.product-catalog {
  padding: 24px;
}

.filters {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  align-items: center;
}

.product-item {
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
  /* Додано transition для box-shadow */
}

.product-item:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-image {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.product-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.placeholder-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  color: #aaa;
}

.product-info {
  padding: 16px;
}

.product-title {
  margin-bottom: 8px;
  font-weight: 500;
}

.product-category {
  font-size: 14px;
  color: #888;
  margin-bottom: 4px;
}

.product-price {
  font-weight: 500;
}

@media (max-width: 768px) {
  .filters {
    flex-direction: column;
    align-items: flex-start;
  }

  .filters>* {
    width: 100%;
    margin-bottom: 1rem;
  }
}
</style>