import api from '@/utils/axios';
import { useToast } from 'vue-toast-notification';

const $toast = useToast();
const base_path = '/api/admin/v1/projects';

const projectAPI = {
  async getAllProjects(userId) {
    return api
      .get(`${base_path}_list/${userId}`)
      .then((response) => response.data)
      .catch((error) => {
        $toast.error('Oops... Try again');
        throw error;
      });
  },
  async createProject(data) {
    return api
      .post(base_path, { project: data })
      .then((response) => {
        $toast.success('Project Created');
        return response.data;
      })
      .catch((error) => {
        $toast.error('Oops... Try again');
        throw error;
      });
  },
  async updateProject(project_id, data) {
    return api
      .put(`${base_path}/${project_id}`, { project: data })
      .then((response) => {
        if (response.data.nestedComponents) {
          $toast.success('Project Updated');
        }
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  async regenerateAPIToken(project_id) {
    return api
      .post(`${base_path}/${project_id}/generate-token`, {})
      .then((response) => {
        if (response.data.nestedComponents) {
          $toast.success('Project Updated');
        }
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  async deleteProject(project_id) {
    return api
      .delete(`${base_path}/${project_id}`)
      .then((response) => {
        $toast.success('Project Deleted');
        return response.data;
      })
      .catch((error) => {
        $toast.error('Oops... Try again');
        throw error;
      });
  },
};

export default projectAPI;
