import screenAPI from '@/api/screens';

export default {
  actions: {
    async getAllPublishedScreens({ commit }) {
      try {
        const screenList = await screenAPI.getAllPublishedScreens();
        commit('updatedScreenList', screenList);
      } catch (error) {
        // Handle error if needed
      }
    },
    async getPublishedScreenById({ commit }, id) {
      try {
        const screen =  await screenAPI.getPublishedScreenById(id);
        commit('addToScreenList', screen);
      } catch (error) {
        // Handle error if needed
      }
    },
    async publishScreenToStore({ _ }, data) {
      try {
        await screenAPI.publishScreenToStore(data);
      } catch (error) {
        // Handle error if needed
      }
    },
    async installScreenFromStore({ _ }, data) {
      try {
        await screenAPI.installScreenFromStore(data);
      } catch (error) {
        // Handle error if needed
      }
    }
  },
  mutations: {
    updatedScreenList(state, screenList) {
      state.screenList = screenList;
    },
    addToScreenList(state, screen) {
      state.screenList.push(screen);
    },
  },
  state: {
    screenList: [],
  },
  getters: {
    allPublishedScreens(state) {
      return state.screenList;
    },
  },
};
