import api from '@/utils/axios';
import { useToast } from 'vue-toast-notification';

const $toast = useToast();
const url = '/api/v1/push/event';

const pushEventAPI = {
  async sendPushEvent(data) {
    const headers = {
      'Content-Type': 'application/json',
      'x-api-token': data.projectToken,
    };

    return api
      .post(
        url,
        {
          event_id: data.eventId,
          user_id: data.userId,
          project_id: data.projectId,
          screen_id: data.screenId,
          action: data.eventName,
          payload: data.payload,
        },
        { headers }
      )
      .then((response) => {
        $toast.success('Sent');
        return response;
      })
      .catch((error) => {
        $toast.error('Oops... Try again');
        throw error;
      });
  },
};

export default pushEventAPI;
