import projectAPI from '../../api/projects';

export default {
  actions: {
    async getAllProjects({ commit, state }, userId) {
      if (state.projectList.length > 0) return;

      try {
        const projectList = await projectAPI.getAllProjects(userId);
        commit('updatedProjectList', projectList);
      } catch (error) {
        // Handle error if needed
      }
    },
    async createProject({ commit }, data) {
      try {
        const project = await projectAPI.createProject(data);
        commit('addToProjectList', project);
      } catch (error) {
        // Handle error if needed
      }
    },
    async updateProject({ commit }, { project_id, data }) {
      try {
        const updatedData = await projectAPI.updateProject(project_id, data);
        commit('updateProjectInList', { project_id, updatedData });
        return updatedData;
      } catch (error) {
        // Handle error if needed
      }
    },
    async regenerateAPIToken({ commit }, { project_id }) {
      try {
        const updatedData = await projectAPI.regenerateAPIToken(project_id);
        commit('updateProjectInList', { project_id, updatedData });
        return updatedData;
      } catch (error) {
        // Handle error if needed
      }
    },
    async deleteProject({ commit }, project_id) {
      try {
        await projectAPI.deleteProject(project_id);
        commit('removeFromProjectList', project_id);
      } catch (error) {
        // Handle error if needed
      }
    },
  },
  mutations: {
    updatedProjectList(state, projectList) {
      state.projectList = projectList;
    },
    addToProjectList(state, project) {
      state.projectList.push(project);
    },
    updateProjectInList(state, { project_id, updatedData }) {
      const index = state.projectList.findIndex(
        (project) => project.id === project_id
      );
      if (index !== -1) {
        // Ensure to maintain the same reference if you want to keep reactivity
        state.projectList.splice(index, 1, {
          ...state.projectList[index],
          ...updatedData,
        });
      }
    },
    removeFromProjectList(state, project_id) {
      state.projectList = state.projectList.filter(
        (project) => project.id !== project_id
      );
    },
  },
  state: {
    projectList: [],
    onPageComponents: [], // Assuming you have this state property
  },
  getters: {
    projectList(state) {
      return state.projectList;
    },
  },
};
