<template>
  <div v-if="product" class="app-detail-screen p-6">
    <div class="app-header flex items-center mb-4 justify-between">
      <h1 class="text-2xl font-bold mr-4">{{ product.title }}</h1>
      <div class="app-icon">
        <img src="https://placehold.co/100" alt="App Icon" class="rounded-lg" />
      </div>
    </div>

    <div class="app-info flex justify-start mb-4">
      <div class="app-rating flex items-center mr-8">
        <span class="rating-stars text-yellow-500">★ {{ Number(Math.random().toFixed(1)) + 4 }}</span>
        <span class="review-count text-gray-500 ml-1">({{ Math.floor(Math.random() * 100) }}k reviews)</span>
      </div>
      <div class="app-downloads text-gray-500">
        <span class="download-count">{{ Math.floor(Math.random() * 600) }}k Downloads</span>
      </div>
    </div>

    <div class="app-actions flex justify-between mb-4">
      <button @click="showModal"
        class="install-button bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Install</button>

      <a-modal :open="modalOpen" title="Select a Project" @ok="installScreenFromStore" @cancel="modalHandleCancel"
        width="500px">
        <!-- Explanation text for the user -->
        <div class="mb-4">
          <a-select class="mb-3" name="projectId" ref="select" v-model:value="selectedProject" style="width: 100%"
            @change="handleChangeProject" placeholder="Select a Project">
            <a-select-option v-for="item in projectList" :key="item.id" :value="item.id">{{ item.name
              }}</a-select-option>
          </a-select>
          <p class="text-sm text-gray-500 mt-2">
            Please select the project you'd like to work with from the dropdown above.
          </p>
        </div>
      </a-modal>
    </div>

    <div class="app-description mb-4">
      <p class="text-gray-700">{{ product.description }}</p>
    </div>

    <div class="app-screenshots mb-4">
      <h3 class="text-lg font-semibold mb-2">Screenshots</h3>
      <div class="grid grid-cols-6 gap-2">
        {{ product.screenshots }}
        <div v-for="screenshot in screenshots" :key="screenshot.id" class="screenshot">
          <img :src="screenshot.url" alt="Screenshot" class="w-full max-h-96 rounded" />
        </div>
      </div>
    </div>
  </div>
  <div v-else>Loading...</div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const modalOpen = ref(false);
    const selectedProject = ref(null);
    const productId = route.params.id;

    const product = computed(() => {
      return store.getters.allPublishedScreens.find(x => x.id === productId);
    });

    const projectList = computed(() => {
      return store.getters.projectList;
    });

    const getUserID = computed(() => {
      return store.getters.getUserID;
    });

    onMounted(() => {
      store.dispatch('getPublishedScreenById', productId);
      store.dispatch('getAllProjects', getUserID.value);
    });

    const screenshots = [
      { id: 1, url: "https://placehold.co/200x400" },
      { id: 2, url: "https://placehold.co/200x400" },
      { id: 3, url: "https://placehold.co/200x400" },
    ];

    const showModal = (_) => {
      modalOpen.value = true;
    }

    const modalHandleCancel = (_) => {
      modalOpen.value = false;
    };

    const handleChangeProject = (value) => {
      selectedProject.value = value;
    };

    const installScreenFromStore = () => {
      store.dispatch('installScreenFromStore', { id: productId, project_id: selectedProject.value });
      modalHandleCancel();
    }

    return {
      product,
      screenshots,
      modalOpen,
      showModal,
      modalHandleCancel,
      selectedProject,
      handleChangeProject,
      projectList,
      installScreenFromStore
    };
  },
};
</script>

<style scoped></style>