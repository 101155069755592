<template></template>

<script setup>
import router from '@/router';
import store from '@/store';
import { useRoute } from 'vue-router';

const route = useRoute();

if(!route.query.user_id || !route.query.token) {
    router.replace({name: "Login"});
}

store.dispatch('validateForgotPasswordToken', {token: route.query.token, user_id: route.query.user_id});
</script>