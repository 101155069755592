<template>
  <div class="container">
    <a-divider>Push Event</a-divider>
    <form id="event-form" @submit.prevent="onSubmit">
      <a-select
        class="mb-3"
        name="projectId"
        ref="select"
        v-model:value="selectedProject"
        style="width: 100%"
        @change="handleChangeProject"
        placeholder="Select project"
      >
        <a-select-option
          v-for="item in this.projectList"
          :key="item.id"
          :value="item.id"
          >{{ item.name }}</a-select-option
        >
      </a-select>
      <a-select
        class="mb-3"
        name="eventName"
        ref="select"
        v-model:value="selectedEvent"
        style="width: 100%"
        @change="handleChangeEvent"
        placeholder="Select event"
      >
        <a-select-option
          v-for="item in this.list"
          :key="item.value"
          :value="item.value"
          >{{ item.title }}</a-select-option
        >
      </a-select>
      <a-select
        class="mb-3"
        name="screenId"
        ref="select"
        v-model:value="selectedScreen"
        style="width: 100%"
        placeholder="Select screen"
      >
        <a-select-option
          v-for="item in this.screenList"
          :key="item.id"
          :value="item.id"
          >{{ item.name }}</a-select-option
        >
      </a-select>
      <a-input class="mb-3" type="text" name="userId" placeholder="User ID" />
      <a-textarea
        v-model:value="template"
        class="mb-3"
        type="text"
        name="payload"
        placeholder="Event Body"
        :rows="4"
      />
      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit">Send event</button>
    </form>
  </div>
</template>

<script>
  import { useToast } from 'vue-toast-notification';
  import { uuid } from 'vue-uuid';
  import { mapActions, mapMutations } from 'vuex';
  const $toast = useToast();

  export default {
    name: 'PushEventPage',
    data() {
      return {
        userId: this.$store.getters.getUserID,
        selectedEvent: null,
        selectedProject: null,
        selectedScreen: null,
        template: null,
      };
    },
    methods: {
      ...mapActions([
        'sendPushEvent',
        'getActionList',
        'getAllProjects',
        'getAllScreens',
      ]),
      ...mapMutations(['updatedScreenList']),
      handleChangeEvent(value) {
        this.selectedEvent = value;
        this.template = JSON.stringify(this.listMap[value].template);
      },
      handleChangeProject(value) {
        this.selectedProject = value;
        this.selectedScreen = null;
        this.getAllScreens(value);
      },
      onSubmit() {
        if (this.validateForm()) {
          const allData = this.getAllData();
          if (this.isValidJSON(allData.payload)) {
            allData.payload = JSON.parse(allData.payload);
            this.sendPushEvent(allData);
          } else {
            $toast.error('Oops... Invalid JSON');
          }
        }
      },
      validateForm() {
        if (!this.selectedProject) {
          $toast.error('Please select a project');
          return false;
        }
        if (!this.selectedEvent) {
          $toast.error('Please select an event');
          return false;
        }
        if (!this.selectedScreen) {
          $toast.error('Please select a screen');
          return false;
        }
        if (!this.userId) {
          $toast.error('Please enter a User ID');
          return false;
        }
        if (!this.template) {
          $toast.error('Please enter the event body');
          return false;
        }
        return true;
      },
      findProjectById(id) {
        return this.projectList.find((item) => item.id === id);
      },
      getAllData() {
        let myForm = document.getElementById('event-form');
        let formData = new FormData(myForm);
        const projectAPIToken = this.findProjectById(this.selectedProject).api_token;
        const data = {};

        Object.assign(data, {
          eventName: this.selectedEvent,
          projectId: this.selectedProject,
          projectToken: projectAPIToken,
          screenId: this.selectedScreen,
          eventId: uuid.v4(),
        });
        for (let [key, val] of formData.entries()) {
          Object.assign(data, { [key]: val });
        }
        return data;
      },
      isValidJSON(text) {
        try {
          JSON.parse(text);
          return true;
        } catch (e) {
          return false;
        }
      },
    },
    mounted() {
      this.updatedScreenList();
      this.getActionList();
      this.getAllProjects(this.userId);
    },
    computed: {
      screenList: {
        get() {
          return this.$store.getters.screenList;
        },
      },
      projectList: {
        get() {
          return this.$store.getters.projectList;
        },
      },
      list: {
        get() {
          return this.$store.getters.actionList;
        },
      },
      listMap: {
        get() {
          const keyValueMap = {};

          this.$store.getters.actionList.forEach((obj) => {
            keyValueMap[obj.value] = obj;
          });

          return keyValueMap;
        },
      },
    },
  };
</script>
