import { createWebHistory, createRouter } from 'vue-router';
import NotFound from '@/components/pages/NotFound.vue';
import ConstructorPage from '@/components/pages/ConstructorPage.vue';
import ConfigPage from '@/components/pages/ConfigPage.vue';
import ScreenListPage from '@/components/pages/ScreenListPage.vue';
import ExamplesPage from '@/components/pages/ExamplesPage.vue';
import NotificationsPage from '@/components/pages/NotificationsPage.vue';
import PushEventPage from '@/components/pages/PushEventPage.vue';
import LogsPage from '@/components/pages/LogsPage.vue';
import ActiveUsersPage from '@/components/pages/ActiveUsersPage.vue';
import ProjectListPage from '@/components/pages/ProjectListPage.vue';
import LoginPage from '@/components/pages/login/LoginPage.vue';
import RegistrationPage from '@/components/pages/registration/RegistrationPage.vue';
import ForgotPasswordPage from '@/components/pages/forgot_password/ForgotPasswordPage.vue';
import CustomWidgetListPage from '@/components/pages/CustomWidgetListPage.vue';
import CustomWidgetPage from '@/components/pages/CustomWidgetPage.vue';
import store from '@/store';
import DocsPage from '@/components/pages/doc/DocsPage.vue';
import DemoAPIDocsPage from '@/components/pages/doc/DemoAPIDocsPage.vue';
import GettingStartedPage from '@/components/pages/doc/GettingStartedPage.vue';
import BeActionsDocPage from '@/components/pages/doc/BeActionsDocPage.vue';
import FeActionsDocPage from '@/components/pages/doc/FeActionsDocPage.vue';
import ThirdPartyToolsPage from '@/components/pages/ThirdPartyToolsPage.vue';
import MonacoEditor from '@/components/MonacoEditor.vue';
import ConstructorPageOld from '@/components/pages/ConstructorPageOld.vue';
import LandingPage from '@/components/pages/landing/LandingPage.vue';
import ScreenConfigPage from '@/components/pages/ScreenConfigPage.vue';
import UserProfilePage from '@/components/pages/UserProfilePage.vue';
import UserEventLogPage from '@/components/pages/UserEventLogPage.vue';
import LoginOauthRedirectPage from '@/components/pages/login/LoginOauthRedirectPage.vue';
import StorePage from '@/components/pages/StorePage.vue';
import ScreenStoreDetailsPage from '@/components/pages/ScreenStoreDetailsPage.vue';
import ResetPasswordRedirectPage from '@/components/pages/reset_password/ResetPasswordRedirectPage.vue';

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: LandingPage,
    meta: {
      auth: false,
      layout: 'auth-layout',
    },
  },
  {
    path: '/login/o/:token/:user_id',
    name: 'LoginOauth',
    component: LoginOauthRedirectPage,
    meta: {
      auth: false,
      layout: 'blank-layout',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      auth: false,
      layout: 'auth-layout',
    },
  },
  {
    path: '/registration',
    name: 'Registration',
    component: RegistrationPage,
    meta: {
      auth: false,
      layout: 'auth-layout',
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPasswordPage,
    meta: {
      auth: false,
      layout: 'auth-layout',
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPasswordRedirect',
    component: ResetPasswordRedirectPage,
    meta: {
      auth: false,
      layout: 'auth-layout',
    },
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: UserProfilePage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/',
    name: 'ProjectList',
    component: ProjectListPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/screens/:project_id',
    name: 'ScreenList',
    component: ScreenListPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/config/:project_id',
    name: 'Config',
    component: ConfigPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/config/screen/:screen_id',
    name: 'ScreenConfig',
    component: ScreenConfigPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: NotificationsPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/push-event',
    name: 'PushEvent',
    component: PushEventPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/logs',
    name: 'Logs',
    component: LogsPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/active-users',
    name: 'ActiveUsers',
    component: ActiveUsersPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/event-log/:user_id',
    name: 'UserEventLog',
    component: UserEventLogPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/notification',
    name: 'Notifications',
    component: NotificationsPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/constructor/:screen_id',
    name: 'Constructor',
    component: ConstructorPage,
    meta: {
      auth: true,
      layout: 'constructor-layout',
    },
  },
  {
    path: '/old-constructor/:screen_id',
    name: 'ConstructorOld',
    component: ConstructorPageOld,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/examples',
    name: 'Examples',
    component: ExamplesPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/docs',
    name: 'Docs',
    component: DocsPage,
    meta: {
      auth: true,
      layout: 'default-layout',
      nav: 'docs',
    },
  },
  {
    path: '/store',
    name: 'Store',
    component: StorePage,
    meta: {
      auth: true,
      layout: 'default-layout',
      nav: 'store',
    },
  },
  {
    path: '/store/:id',
    name: 'ScreenStoreDetails',
    component: ScreenStoreDetailsPage,
    meta: {
      auth: true,
      layout: 'default-layout',
      nav: 'store',
    },
  },
  {
    path: '/docs/demo-api',
    name: 'DemoAPI',
    component: DemoAPIDocsPage,
    meta: {
      auth: true,
      layout: 'default-layout',
      nav: 'docs',
    },
  },
  {
    path: '/docs/getting-started',
    name: 'GettingStarted',
    component: GettingStartedPage,
    meta: {
      auth: true,
      layout: 'default-layout',
      nav: 'docs',
    },
  },
  {
    path: '/docs/be-actions',
    name: 'BeActionsDoc',
    component: BeActionsDocPage,
    meta: {
      auth: true,
      layout: 'default-layout',
      nav: 'docs',
    },
  },
  {
    path: '/docs/fe-actions',
    name: 'FeActionsDoc',
    component: FeActionsDocPage,
    meta: {
      auth: true,
      layout: 'default-layout',
      nav: 'docs',
    },
  },
  {
    path: '/custom_widgets',
    name: 'CustomWidgetList',
    component: CustomWidgetListPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/custom_widgets/:widget_id',
    name: 'CustomWidget',
    component: CustomWidgetPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/third-party-tools',
    name: 'ThirdPartyTools',
    component: ThirdPartyToolsPage,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/monaco',
    name: 'MonacoEditor',
    component: MonacoEditor,
    meta: {
      auth: true,
      layout: 'default-layout',
    },
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound,
    meta: {
      auth: false,
      layout: 'blank-layout',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.auth && store.getters.isLoggedIn) {
    return next({ name: 'ProjectList' });
  }
  if (to.meta.auth && !store.getters.isLoggedIn) {
    return next({ name: 'Login' });
  }
  next();
});

export default router;
