import configAPI from '../../api/configs';

export default {
  actions: {
    async requireLogin({ commit }, data) {
      try {
        const responseData = await configAPI.requireLogin(data);
        commit('updateConfig', responseData);
      } catch (error) {
        // Handle error if needed
      }
    },
    async enableMaintenance({ commit }, data) {
      try {
        const responseData = await configAPI.enableMaintenance(data);
        commit('updateConfig', responseData);
      } catch (error) {
        // Handle error if needed
      }
    },
    async getConfigs({ commit, getters }, project_id) {

      if(getters.configList && getters.configList.lenght){
        return getters.configList;
      }

      try {
        const configs = await configAPI.getConfigs(project_id);
        commit('updateConfigs', configs);
        return configs;
      } catch (error) {
        // Handle error if needed
      }
    },
    async updateConfig({ commit }, data) {
      try {
        const responseData = await configAPI.updateConfig(data);
        commit('updateConfig', responseData);
      } catch (error) {
        // Handle error if needed
      }
    },
    async updateSystemEnv({ commit }, data) {
      try {
        const responseData = await configAPI.updateSystemEnv(data);
        commit('updateConfig', responseData);
      } catch (error) {
        // Handle error if needed
      }
    },
    async updateUserEnv({ commit }, data) {
      try {
        const responseData = await configAPI.updateUserEnv(data);
        commit('updateConfig', responseData);
      } catch (error) {
        // Handle error if needed
      }
    },
    async removeUserEnv({ commit }, data) {
      try {
        const responseData = await configAPI.removeUserEnv(data);
        commit('updateConfig', responseData);
      } catch (error) {
        // Handle error if needed
      }
    },
  },
  mutations: {
    updateConfigs(state, data) {
      state.configs = data;
    },
    updateConfig(state, data) {
      state.configs = {
        ...state.configs,
        [data.name]: data.value,
      };
    },
  },
  state: {
    configs: {},
  },
  getters: {
    configList(state) {
      return state.configs;
    },
  },
};
