import authAPI from '@/api/auth';
import router from '@/router';
import store from '@/store';

export default {
  actions: {
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('logout');
        store.commit('updatedProjectList', []);
        resolve();
      });
    },
    login({ commit }, { email, password }) {
      return new Promise((resolve, reject) => {
        authAPI
          .login(email, password)
          .then((data) => {
            commit('login', data);
            router.push({ name: 'ProjectList' });
            resolve(data.token);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    register(_context, { email, password }) {
      return new Promise((resolve, reject) => {
        authAPI
          .register(email, password)
          .then((response) => {
            router.push({ name: 'Login' });
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    forgotPassword(_context, { email }) {
      return new Promise((resolve, reject) => {
        authAPI
          .forgotPassword(email)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    validateForgotPasswordToken({commit}, { token, user_id }) {
      return new Promise((resolve, reject) => {
        authAPI
          .validateForgotPasswordToken(token, user_id)
          .then((response) => {
            commit('login', response);
            router.push({ name: 'UserProfile' });
            resolve(response);
          })
          .catch((error) => {
            router.push({ name: 'Login' });
            reject(error);
          });
      });
    },
  },
  mutations: {
    logout(state) {
      localStorage.removeItem('user-token');
      localStorage.removeItem('user-id');
      state.token = '';
      state.userId = null;
    },
    login(state, data) {
      localStorage.setItem('user-token', data.token);
      localStorage.setItem('user-id', data.user_id);
      state.token = data.token;
      state.userId = data.user_id;
    },
  },
  state: {
    token: localStorage.getItem('user-token') || '',
    userId: localStorage.getItem('user-id') || '',
  },
  getters: {
    isLoggedIn(state) {
      return state.token !== '';
    },
    getUserID(state) {
      return state.userId;
    },
  },
};
